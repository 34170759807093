import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { BackLoadingSimple } from "../../composant/loading/backLoading";
import Indexdasbord from "../../composant/navbar/indexDashbord";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TablePagination from "@mui/material/TablePagination";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar, Alert } from "@mui/material";
import {
  Addparams,
  DeleteParams,
  GetParamsByImprimant,
  Updateparams,
  inisialAlertMessageDimention,
} from "../../store/slice/ParamsDashbordSlice";
import DeleteConfirmationDialog from "../../composant/admin/confirmationDelete";
import AddParamsPopup from "../../composant/admin/AddParamsPopup";
import UpdateParamsPopup from "../../composant/admin/UpdateParamsPopup";

export default function ParamsDahbord() {
  const { loading } = useSelector((state) => state.parametreImprimant);
  return (
    <>
      <Indexdasbord Children={<ParamsPage />} />
      <BackLoadingSimple open={loading} />
    </>
  );
}

function ParamsPage() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { ParamsData } = useSelector((state) => state.parametreImprimant);
  const { alertMessage, etat } = useSelector(
    (state) => state.parametreImprimant
  );
  const [MessageDelete, setMessageDelete] = useState(false);
  const [DeleteId, setDeleteId] = useState();
  const [PopupAdd, setPopupAdd] = useState(false);
  const [IdAdd, SetIdAdd] = useState();
  const [openUpdate, setopenUpdate] = useState(false);
  const [DataUpdate, setDataUpdate] = useState();
  const dispatch = useDispatch();
  const getData = React.useCallback(() => {
    const data = {
      limit: rowsPerPage,
      page: page,
    };
    dispatch(GetParamsByImprimant(data));
  }, [dispatch, page, rowsPerPage]);
  useEffect(() => {
    getData();
  }, [dispatch, page, rowsPerPage, getData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const dispatchDelete = () => {
    setMessageDelete(false);
    dispatch(DeleteParams(DeleteId)).then((result) => {
      if (DeleteParams.fulfilled.match(result)) {
        getData();
      }
    });
  };
  function handleClose() {
    dispatch(inisialAlertMessageDimention());
  }

  function dispatchAddparams(data) {
    setPopupAdd(false);
    dispatch(Addparams(data)).then((result) => {
      if (Addparams.fulfilled.match(result)) {
        getData();
      }
    });
  }

  function Update(data) {
    setopenUpdate(false);
    dispatch(Updateparams(data)).then((result) => {
      if (Updateparams.fulfilled.match(result)) {
        getData();
      }
    });
  }
  return (
    <Box>
      <Paper sx={{ width: "90%", overflow: "hidden", marginLeft: "5%" }}>
        <h1> page : parametre</h1>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table aria-label="collapsible table">
            <TableBody>
              {ParamsData?.data?.map((i) => (
                <Row
                  row={i}
                  setDeleteId={setDeleteId}
                  setMessageDelete={setMessageDelete}
                  setPopupAdd={setPopupAdd}
                  SetIdAdd={SetIdAdd}
                  setopenUpdate={setopenUpdate}
                  setDataUpdate={setDataUpdate}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={ParamsData?.totalCount || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Snackbar
        open={alertMessage}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "buttom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={etat.error ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {etat.message}
        </Alert>
      </Snackbar>
      <DeleteConfirmationDialog
        open={MessageDelete}
        onClose={() => setMessageDelete(false)}
        onConfirm={dispatchDelete}
      />
      <AddParamsPopup
        open={PopupAdd}
        onClose={() => {
          setPopupAdd(false);
        }}
        idImprimant={IdAdd}
        send={dispatchAddparams}
      />
      <UpdateParamsPopup
        open={openUpdate}
        onClose={() => setopenUpdate(false)}
        params={DataUpdate}
        send={Update}
      />
    </Box>
  );
}

function Row(props) {
  const {
    row,
    setMessageDelete,
    setDeleteId,
    setPopupAdd,
    SetIdAdd,
    setopenUpdate,
    setDataUpdate,
  } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" align="center" colSpan={2}>
          <h3> Imprimant : {row.nomImprimant}</h3>
        </TableCell>
        <TableCell align="right">
          <Button
            onClick={() => {
              SetIdAdd(row.id);
              setPopupAdd(true);
            }}
          >
            Ajouter
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                {row.demontions[0] && (
                  <TableHead>
                    <TableRow>
                      <TableCell>nom produit</TableCell>
                      <TableCell>marginLeft</TableCell>
                      <TableCell>marginTop</TableCell>
                      <TableCell>Height</TableCell>
                      <TableCell>Width</TableCell>
                      <TableCell>back height</TableCell>
                      <TableCell>back width</TableCell>
                      <TableCell>type Croper H</TableCell>
                      <TableCell>type Croper W</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                )}

                <TableBody>
                  {row.demontions.map((d) => (
                    <TableRow>
                      <TableCell align="center">
                        {d.Produit.nomProduit}
                      </TableCell>
                      <TableCell align="center">{d.marginLeft}</TableCell>
                      <TableCell align="center">{d.marginTop}</TableCell>
                      <TableCell align="center">{d.Height}</TableCell>
                      <TableCell align="center">{d.Width}</TableCell>
                      <TableCell align="center">{d.backH}</TableCell>
                      <TableCell align="center">{d.backW}</TableCell>
                      <TableCell align="center">{d.typeCroperH}</TableCell>
                      <TableCell align="center">{d.typeCroperW}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          onClick={() => {
                            setopenUpdate(true);
                            setDataUpdate(d);
                          }}
                        >
                          <EditIcon color="primary" />
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          onClick={() => {
                            setDeleteId(d.id);
                            setMessageDelete(true);
                          }}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
