import DrawerAppBar from "./Navbar";
import MenuDasbord from "./dashbodMenu";
import { Box } from "@mui/material";

function Indexdasbord({Children}) {
  return (
    <div style={{ minHeight: "100vh" , backgroundColor:"#f7f7f7" }}>
      <DrawerAppBar />
      <Box sx={{ position: "relative", zIndex: 1 }}>
        <Box
          sx={{
            marginTop: "100px",
            display: { xs: "none", md: "flex" },
            flexDirection: "row",
          }}
        >
          <Box>
            <MenuDasbord />
          </Box>
          <Box width="100%">{Children}</Box>
        </Box>
      </Box>
    </div>
  );
}

export default Indexdasbord
