import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

function DeleteConfirmationDialog({ open, onClose, onConfirm, content }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirmation de la suppression</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
      <Button onClick={onClose} color="primary" >
      annuler
        </Button>
        <Button onClick={onConfirm} color="error">
        Supprimer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteConfirmationDialog;