import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Api_Token } from "../../env";

export const GetImprimantData = createAsyncThunk(
  "get imprimant data",
  async () => {
    const response = await Api_Token.get("imprimant/get");
    return response.data;
  }
);

export const SwitchImprimant = createAsyncThunk(
  "switch imprimant",
  async (id) => {
    const response = await Api_Token.get(`imprimant/switch/${id}`);
    return response.data;
  }
);

export const AddImprimant = createAsyncThunk("add imprimant ", async (data) => {
  const response = await Api_Token.post("imprimant/add", data);
  return response.data;
});

export const DeleteImprimant = createAsyncThunk(
  "delete imprimant ",
  async (id) => {
    const response = await Api_Token.delete(`imprimant/delete/${id}`);
    return response.data;
  }
);
const initialState = {
  loading: false,
  ImprimantData: {},
  alertMessage: false,
  etat: {
    error: false,
    message: "",
  },
};

export const ImprimantSlice = createSlice({
  name: "imprimant slice",
  initialState,
  reducers: {
    inisialAlertMessage: (state, action) => {
      state.alertMessage = false;
      state.etat = {
        error: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetImprimantData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetImprimantData.fulfilled, (state, action) => {
      state.loading = false;
      state.ImprimantData = action.payload;
    });
    builder.addCase(GetImprimantData.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(SwitchImprimant.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(SwitchImprimant.fulfilled, (state, action) => {
      state.alertMessage = true;
      state.etat.message = "imprimant switch en succès";
    });
    builder.addCase(SwitchImprimant.rejected, (state, action) => {
      state.loading = false;
      state.alertMessage = true;
      state.etat.error = true;
      state.etat.message = action.error.message;
    });
    builder.addCase(AddImprimant.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(AddImprimant.fulfilled, (state, action) => {
      state.loading = false;
      state.alertMessage = true;
      state.etat.message = "ajouter imprimant  en succès ";
    });
    builder.addCase(AddImprimant.rejected, (state, action) => {
      state.loading = false;
      state.alertMessage = true;
      state.etat.error = true;
      state.etat.message = action.error.message;
    });
    builder.addCase(DeleteImprimant.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(DeleteImprimant.fulfilled, (state, action) => {
      state.loading = false;
      state.alertMessage = true;
      state.etat.message = "imprimant supprimer en succès ";
    });
    builder.addCase(DeleteImprimant.rejected, (state, action) => {
      state.loading = false;
      state.alertMessage = true;
      state.etat.error = true;
      state.etat.message = action.error.message;
    });
  },
});

export default ImprimantSlice.reducer;
export const { inisialAlertMessage } = ImprimantSlice.actions;
