import { configureStore } from "@reduxjs/toolkit";
import GestionImage from "./slice/gestionImageSlice";
import InfoClient from "./slice/InfoClientSlice";
import DataAppSlice from "./slice/DataApp";
import AuthSlice from "./slice/AuthSlice";
import CommandDahbordSlice from "./slice/CommandDahbordSlice";
import ImprimantDasbordSlice from "./slice/ImprimantDasbordSlice";
import ParamsDashbordSlice from "./slice/ParamsDashbordSlice";

const store = configureStore({
  reducer: {
    gestionImage: GestionImage,
    InfoCommand: InfoClient,
    dataApp: DataAppSlice,
    Auth: AuthSlice,
    commands: CommandDahbordSlice,
    imprimant: ImprimantDasbordSlice,
    parametreImprimant: ParamsDashbordSlice,
  },
});

export default store;
