import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import logo from "../../image/okprintLogo.jpg";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Api_Token } from "../../env";

export default function SearchCommand() {
  const [code, setcode] = useState();
  const navigate = useNavigate();
  function search() {
    Api_Token.get(`command/traitebycode/${code}`)
      .then((response) => {
        navigate(`/admin/imageTraite/${response.data.idCommand}`);
      })
      .catch((error) => {
        console.error("Error fetching command:", error);
        alert("Command not found");
      });
  }

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        container
        textAlign="center"
        spacing={2}
        direction="column"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Box
            component="img"
            src={logo}
            alt="OK Print Logo"
            sx={{ maxWidth: "300px", mb: 2 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: {
                xs: "14px",
                md: "26px",
                lg: "28px",
                xl: "30px",
              },
            }}
          >
            Welcome to OK Print
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            placeholder="Code de Command"
            label="Code de Command"
            name="code"
            sx={{ width: "500px", mt: 2 }}
            value={code}
            onChange={(e) => setcode(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={search} sx={{ mt: 2 }}>
            Search
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
