import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Api_Token } from "../../env";

//command/getcommad?page=${page}&limit=${rowsPerPage
export const GetCommand = createAsyncThunk("command", async (data) => {
  const response = await Api_Token.get(
    `command/get?page=${data.page + 1}&limit=${data.rowsPerPage}&nom=${
      data.nomFilter
    }&telephone=${data.telephoneFilter}&date=${data.dateFilter}`
  );
  return response.data;
});

export const TraiteCommand = createAsyncThunk(
  " command ",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api_Token.post(`command/traite`, data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const GetImageParCommand = createAsyncThunk(
  "images traite par id ",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api_Token.get(`command/imageTraite/${id}`);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const EditeImage = createAsyncThunk(
  "croper image ",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api_Token.post(`command/editImage`, data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  loading: false,
  commands: {},
  PopupData: {},
  alertMessage: false,
  etat: {
    error: false,
    message: "",
  },
};

export const CommandDashbordSlice = createSlice({
  name: "command",
  initialState,
  reducers: {
    inisialAlertMessageCommand: (state, action) => {
      state.alertMessage = false;
      state.etat = {
        error: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetCommand.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetCommand.fulfilled, (state, action) => {
      state.loading = false;
      state.commands = action.payload;
    });
    builder.addCase(GetCommand.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(TraiteCommand.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(TraiteCommand.fulfilled, (state, action) => {
      state.alertMessage = true;
      state.etat.message = action.payload;
    });
    builder.addCase(TraiteCommand.rejected, (state, action) => {
      state.loading = false;
      state.alertMessage = true;
      state.etat.error = true;
      state.etat.message = action.payload;
    });
    builder.addCase(GetImageParCommand.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetImageParCommand.fulfilled, (state, action) => {
      state.loading = false;
      state.PopupData = action.payload;
    });
    builder.addCase(GetImageParCommand.rejected, (state, action) => {
      state.loading = false;
      state.alertMessage = true;
      state.etat.error = true;
      state.etat.message = action.error.message;
    });
    builder.addCase(EditeImage.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(EditeImage.rejected, (state, action) => {
      state.loading = false;
      state.state.alertMessage = true;
      state.etat.error = true;
      state.etat.message = action.payload;
    });
  },
});

export default CommandDashbordSlice.reducer;
export const { inisialAlertMessageCommand } = CommandDashbordSlice.actions;
