import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Api_Token } from "../../env";

export const GetParamsByImprimant = createAsyncThunk(
  "params imprimant",
  async (data) => {
    const response = await Api_Token.get(
      `/dimension/get?limit=${data.limit}&page=${data.page}`
    );
    return response.data;
  }
);

export const DeleteParams = createAsyncThunk(
  "delete params",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api_Token.delete(`/dimension/delete/${id}`);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const Addparams = createAsyncThunk(
  " add params ",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api_Token.post("/dimension/add", data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const Updateparams = createAsyncThunk(
  " Update params ",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api_Token.post("/dimension/update", data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  loading: false,
  ParamsData: {},
  alertMessage: false,
  etat: {
    error: false,
    message: "",
  },
};

const ParamsSlice = createSlice({
  name: "params slice",
  initialState,
  reducers: {
    inisialAlertMessageDimention: (state, action) => {
      state.alertMessage = false;
      state.etat = {
        error: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetParamsByImprimant.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetParamsByImprimant.fulfilled, (state, action) => {
      console.log(action);
      state.loading = false;
      state.ParamsData = action.payload;
    });
    builder.addCase(GetParamsByImprimant.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(DeleteParams.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(DeleteParams.fulfilled, (state, action) => {
      state.alertMessage = true;
      state.etat.message = action.payload;
    });
    builder.addCase(DeleteParams.rejected, (state, action) => {
      state.loading = false;
      state.alertMessage = true;
      state.etat.error = true;
      state.etat.message = action.payload;
    });
    builder.addCase(Addparams.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Addparams.fulfilled, (state, action) => {
      state.alertMessage = true;
      state.etat.message = "Dimensions ajoutées avec succès";
    });
    builder.addCase(Addparams.rejected, (state, action) => {
      state.loading = false;
      state.alertMessage = true;
      state.etat.error = true;
      state.etat.message = action.payload;
    });
    builder.addCase(Updateparams.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Updateparams.fulfilled, (state, action) => {
      state.alertMessage = true;
      state.etat.message = action.payload;
    });
    builder.addCase(Updateparams.rejected, (state, action) => {
      state.loading = false;
      state.alertMessage = true;
      state.etat.error = true;
      state.etat.message = action.payload;
    });
  },
});

export default ParamsSlice.reducer;
export const { inisialAlertMessageDimention } = ParamsSlice.actions;
