import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api_base } from "../../env";
import Cookies from "js-cookie";
import axios from "axios";

export const send_login = createAsyncThunk("login" , async (data)=>{
  const response = await axios.post(`${api_base}magasin/login`, data);
  return response.data
})

const initialState = {
  loading: false,
  IsAuth: false,
  TypeUser: null,
};

export const AuthSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    checkIsAuth:(state ,action)=>{
      state.IsAuth =Cookies.get("token") ? true : false
    },
    CheckEmailRegulaire:(state , action)=>{
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(action.email);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(send_login.pending , (state , action)=>{
        state.loading = true
    })
    builder.addCase(send_login.fulfilled , (state , action)=>{
        state.loading = false
        Cookies.set("token" ,action.payload.token ,{ expires: 1 } )
        state.IsAuth = true
    })
    builder.addCase(send_login.rejected , (state , action) =>{
        state.loading = false
    })
  },
});

export default AuthSlice.reducer;
export const{checkIsAuth }=AuthSlice.actions
