import { Box, Button, IconButton, TextField } from "@mui/material";
import { BackLoadingSimple } from "../../composant/loading/backLoading";
import Indexdasbord from "../../composant/navbar/indexDashbord";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Snackbar, Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  AddImprimant,
  DeleteImprimant,
  GetImprimantData,
  SwitchImprimant,
  inisialAlertMessage,
} from "../../store/slice/ImprimantDasbordSlice";

import { Delete } from "@mui/icons-material";
import DeleteConfirmationDialog from "../../composant/admin/confirmationDelete";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function ImprimnatDashbord() {
  const loading = useSelector((state) => state.imprimant.loading);
  return (
    <>
      <Indexdasbord Children={<ImprimantPage />} />
      <BackLoadingSimple open={loading} />
    </>
  );
}

function ImprimantPage() {
  const imprimant = useSelector((state) => state.imprimant.ImprimantData);
  const { alertMessage, etat } = useSelector((state) => state.imprimant);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [Newname, setNewname] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [idDelete, setIdDelete] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetImprimantData());
  }, [dispatch]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function Switch(id) {
    dispatch(SwitchImprimant(id)).then((result) => {
      dispatch(GetImprimantData());
    });
  }

  function Add() {
    const data = {
      nom: Newname,
    };
    dispatch(AddImprimant(data)).then((result) => {
      if (AddImprimant.fulfilled.match(result)) {
        dispatch(GetImprimantData());
      }
    });
  }
  function suppImprimant() {
    setOpenDelete(false);
    dispatch(DeleteImprimant(idDelete)).then((result) => {
      if (DeleteImprimant.fulfilled.match(result)) {
        dispatch(GetImprimantData());
      }
    });
  }

  function handleClose() {
    dispatch(inisialAlertMessage());
  }
  return (
    <Box>
      <Paper sx={{ width: "90%", overflow: "hidden", marginLeft: "5%" }}>
        <h1> page : imprimant</h1>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} sx={{ textAlign: "center" }}>
                  <TextField
                    label="nom"
                    variant="outlined"
                    value={Newname}
                    onChange={(e) => {
                      setNewname(e.target.value);
                    }}
                  />
                </TableCell>
                <TableCell colSpan={2} sx={{ textAlign: "center" }}>
                  <Button variant="contained" onClick={Add}>
                    Ajouter
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {imprimant?.data?.map((i) => (
                <TableRow>
                  <TableCell colSpan={2} sx={{ textAlign: "center" }}>
                    <h3 style={{ textAlign: "center" }}>{i.nomImprimant}</h3>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <FormControlLabel
                      checked={i.etat}
                      control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                      label={i.etat ? "on" : "off"}
                      onClick={() => {
                        if (!i.etat) {
                          Switch(i.id);
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <IconButton
                      onClick={() => {
                        setIdDelete(i.id);
                        setOpenDelete(true);
                      }}
                    >
                      <Delete color="error" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={imprimant?.totalCount || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Snackbar
        open={alertMessage}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "buttom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={etat.error ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {etat.message}
        </Alert>
      </Snackbar>
      <DeleteConfirmationDialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        onConfirm={suppImprimant}
      />
    </Box>
  );
}
