import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api_base } from "../../env";
import axios from "axios";


const initialState = {
  loading: false,
  Produits: [],
  Magasins: [],
};

export const GetProduits = createAsyncThunk("get produits", async () => {
  const response = await axios.get(`${api_base}produit/getProduits`);
  return response.data;
});

export const GetMagasin = createAsyncThunk("get Magasin", async () => {
  const response = await axios.get(`${api_base}magasin/getMagasin`);
  return response.data;
});

export const DataAppSlice = createSlice({
  name: "data app",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetProduits.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetProduits.fulfilled, (state, action) => {
      state.loading = false;
      state.Produits = action.payload;
    });
    builder.addCase(GetProduits.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(GetMagasin.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetMagasin.fulfilled, (state, action) => {
      state.loading = false;
      state.Magasins = action.payload;
      console.log(action.payload);
    });
    builder.addCase(GetMagasin.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default DataAppSlice.reducer;
