import { Box, Grid, TextField, Typography, Autocomplete } from "@mui/material";
import axios from "axios";
import { useRef, useState } from "react";
import { api_base } from "../../env";
import { useNavigate, useParams } from "react-router";
import BackLoading from "../../composant/loading/backLoading";
import { useDispatch, useSelector } from "react-redux";
import { VidePanier } from "../../store/slice/gestionImageSlice";
import { ImportButton } from "../../composant/UploadFiles";
import MessageSucces from "../../composant/message/succesMessage";
import { StorageDataClient } from "../../store/slice/InfoClientSlice";
import DrawerAppBar from "../../composant/navbar/Navbar";

export default function InfoClient() {
  const formRef = useRef();
  const Navigate = useNavigate();
  const [magasinid, setMagasinid] = useState("");
  const [Loading, setLoading] = useState(false);
  const [Progress, setProgress] = useState(0);
  //  const [messageError, setMessageerror] = useState();
  const [succes, setSucces] = useState(false);
  const [codeCommand, setCodeCommand] = useState();
  const magasin = useSelector((state) => state.dataApp.Magasins);
  const clientInfo = useSelector((state) => state.InfoCommand.clientCommand);
  const allImages = useSelector((state) => state.gestionImage.allImages);
  const { type } = useParams();

  const dispatch = useDispatch();
  /*
  async function submitCommand(e) {
    setMessageerror();
    e.preventDefault();
    const { nom, telephone, magasin, adresse, ville, evenments } = e.target;
    {
    const trimmedPhone = telephone.value.trim();
    if (trimmedPhone.length < 10) {
      setMessageerror("numro telephone invalide");
      return;
    }
    }
    setLoadingSimple(true);
    const data = {
      nom: nom.value,
      telephone: telephone.value,
      magasin: magasin?.value || null,
      adresse: adresse?.value || null,
      ville: ville?.value || null,
      evenments: evenments?.value || null,
    };
    await axios
      .post(`${api_base}nodeCamera/command/add`, data)
      //.post(`http://192.168.1.9:5000/nodeCamera/command/add`, data)
      .then((response) => {
        sendData(response.data.id);
        dispatch(StorageDataClient(data));
      })
      .catch((error) => {
        alert("error");
        console.log(error);
      });
    setLoadingSimple(false);
  }
  async function sendData(id) {
    setNumberUpload(polaroid.length + instax.length + Evenements.length);
    const Alldata = [...polaroid, ...instax, ...Evenements];
    setLoading(true);
    const dataNotUpload = [];
    for (let index = 0; index < Alldata.length; index++) {
      const data = { ...Alldata[index], idcommand: id };
      try {
        await axios.post(`${api_base}nodeCamera/addManyImage`, data);
        setNumberfileUpload(index + 1);
      } catch (error) {
        dataNotUpload.push(Alldata[index]);
        continue;
      }
    }
    dispatch(VidePanier());
    setLoading(false);
    if (!dataNotUpload.length) {
      setSucces(true);
    } else {
      setTableauImage(dataNotUpload);
      Navigate(`/upload/${id}`);
    }
  }
*/
  async function NewCommand(e) {
    setLoading(true);
    e.preventDefault();
    const { nom, telephone, adresse, code } = e.target;
    const formData = new FormData();
    formData.append("nom", nom.value);
    formData.append("telephone", telephone.value);
    formData.append("adresse", adresse?.value || null);
    formData.append("magasin", magasinid);
    formData.append("code", code?.value || null);
    let dataFiles = [];
    Object.keys(allImages).forEach((key, i) => {
      allImages[key].forEach((item, j) => {
        formData.append(`${item.name}${i}${j}`, item.file);
        const dataFile = {
          nomFile: `${item.name}${i}${j}`,
          idproduit: item.idProduit,
          text: item.text ? item.text : null,
        };
        dataFiles.push(dataFile);
      });
    });
    console.log(dataFiles);
    formData.append("dataFiles", JSON.stringify(dataFiles));
    await axios
      .post(`${api_base}command/Add`, formData, {
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      })
      .then((response) => {
        setCodeCommand(response.data.code);
        setSucces(true);
        dispatch(VidePanier());
      })
      .catch(() => {
        alert("error");
      });
    const data = {
      nom: nom.value,
      telephone: telephone.value,
      adresse: adresse?.value || null,
    };
    dispatch(StorageDataClient(data));
    setLoading(false);
  }

  return (
    <>
      <DrawerAppBar />
      <div style={{ background: "#f7f7f7", minHeight: "100vh" }}>
        <Box>
          <Box component="form" onSubmit={NewCommand} ref={formRef}>
            <Typography variant="h3" fontSize={40} textAlign="center">
              info client
            </Typography>

            <Grid
              container
              rowSpacing={4}
              marginTop="4%"
              width="40%"
              marginLeft="30%"
              sx={{
                marginTop: "4%",
                width: "40%",
                marginLeft: "30%",
                "@media (max-width: 700px)": {
                  width: "100%",
                  marginLeft: "0%",
                  padding: "3%",
                },
              }}
            >
              <Grid item xs={12}>
                <Typography variant="h3" fontSize={20} textAlign="center">
                  Je m'inscris et je confirme ma commande
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  placeholder="telephone"
                  name="telephone"
                  label="telephone"
                  fullWidth
                  //  error={messageError}
                  //  helperText={messageError && messageError}
                  required
                  defaultValue={clientInfo?.telephone}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  placeholder="Nom"
                  label="Nom"
                  name="nom"
                  fullWidth
                  required
                  defaultValue={clientInfo?.nom}
                />
              </Grid>

              {type === "inStore" ? (
                <>
                  <Grid item xs={12}>
                    <Autocomplete
                      disablePortal
                      id="magasin"
                      options={magasin}
                      getOptionLabel={(option) => option.Nom}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setMagasinid(newValue.id);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="magasin"
                          required
                          name="magasin"
                        />
                      )}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <TextField
                    placeholder="Adresse"
                    label="Adresse"
                    name="adresse"
                    defaultValue={clientInfo?.adresse}
                    fullWidth
                    required
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  placeholder="code promo"
                  label="code promo"
                  name="code"
                  fullWidth
                />
              </Grid>
              {/*Evenements.length > 0 && (
            <Grid item xs={12}>
              <TextField
                placeholder="Evenements"
                label="Evenements"
                name="evenments"
                fullWidth
                required
                defaultValue={clientInfo?.evenments}
              />
            </Grid>
          )*/}
              <Grid item xs={12}>
                <ImportButton variant="contained" type="submit" fullWidth>
                  commande
                </ImportButton>
              </Grid>
            </Grid>
          </Box>
          <MessageSucces
            open={succes}
            code={codeCommand}
            onClose={() => {
              setSucces(false);
              Navigate("/");
            }}
          />
          <BackLoading open={Loading} Progress={Progress} />
        </Box>
      </div>
    </>
  );
}
