
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initializeState } from "./store/slice/gestionImageSlice";
import { GetInfoClient } from "./store/slice/InfoClientSlice";
import { GetMagasin, GetProduits } from "./store/slice/DataApp";
import { BackLoadingSimple } from "./composant/loading/backLoading";
import Login from "./page/Admin/auth/login";
import { checkIsAuth } from "./store/slice/AuthSlice";
import IndexRoute from "./indexRoute";

function App() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.dataApp);
  const { IsAuth } = useSelector((state) => state.Auth);
  const [check, setCheck] = useState(false);
  console.log(IsAuth);
  useEffect(() => {
    fetchData();
  }, [dispatch]);
  const fetchData = async () => {
    dispatch(GetProduits());
    dispatch(GetMagasin())
    dispatch(initializeState());
    dispatch(GetInfoClient());
    await dispatch(checkIsAuth()); // Correctly awaiting the dispatch
    setCheck(true);
  };

  return (
    <div className="App">
      {!loading && check ? <IndexRoute /> : <BackLoadingSimple open={true} />}
    </div>
  );
}

export default App;
