import React from "react";
import {
  Dialog,
  Card,
  CardContent,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import succesImage from "../../image/succes.jpg";
import CancelIcon from "@mui/icons-material/Cancel";

const styles = {
  dialog: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    position: "relative",
    width: "fit-content",
    height: "50%",
    maxWidth: "100%",
    borderRadius: "20px",
  },
  closeButton: {
    position: "absolute",
    top: "5px",
    right: "5px",
    color: "red",
    marginRight: "10px",
  },
  content: {
    textAlign: "center",
    padding: "20px",
    marginBottom: "20px",
    marginTop: "20px", // Add margin bottom to create space between the icon and text
  },
  imageContainer: {
    background: "#f5f5f5",
    borderRadius: "50%",
    width: "120px",
    height: "120px",
    padding: "10px",
    margin: "0 auto 20px",
    // Add styles for the image container
  },
  button: {
    backgroundColor: "#FF1744",
    color: "white",
    textTransform: "none",
    width: "100%",
    padding: "10px 0",
    marginTop: "20px",
  },
};

function ConfirmationDelete({ open, onClose, onConfirme }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      style={styles.dialog}
      PaperProps={{ style: { borderRadius: 20 } }}
    >
      <Card style={styles.card}>
        <IconButton style={styles.closeButton} onClick={onClose}>
          <CancelIcon />
        </IconButton>
        <CardContent style={styles.content}>
          <Typography variant="h5" component="h2" gutterBottom>
            Êtes-vous sûr de vouloir supprimer cet élément ? ❌
          </Typography>
          <Typography variant="body1" color="textSecondary" component="p">
            Cette action ne peut pas être annulée. Veuillez confirmer si vous
            souhaitez continuer.
          </Typography>
          <Button
            variant="contained"
            onClick={onConfirme}
            style={styles.button}
          >
            Confirmer la Suppression
          </Button>
        </CardContent>
      </Card>
    </Dialog>
  );
}

export default ConfirmationDelete;
