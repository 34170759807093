import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const dbVersion = 1;
const dbName = "imageDatabase";
const storeName = "images";

function openDB() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, dbVersion);

    request.onerror = (event) => {
      console.error("IndexedDB error:", request.error);
      reject(request.error);
    };

    request.onsuccess = (event) => {
      resolve(request.result);
    };

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, { keyPath: "type" });
      }
    };
  });
}

async function getImages() {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([storeName], "readonly");
    const objectStore = transaction.objectStore(storeName);
    const request = objectStore.getAll();

    request.onerror = (event) => {
      reject(request.error);
    };

    request.onsuccess = (event) => {
      resolve(
        request.result.length > 0
          ? request.result[0]
          : { polaroid: [], instax: [], Evenements: [] }
      );
    };
  });
}

async function saveImages(images) {
  const db = await openDB();
  const transaction = db.transaction([storeName], "readwrite");
  const objectStore = transaction.objectStore(storeName);
  const request = objectStore.put({ ...images, type: "imageTypes" });

  return new Promise((resolve, reject) => {
    request.onerror = (event) => {
      reject(request.error);
    };

    request.onsuccess = (event) => {
      resolve();
    };
  });
}
export const VideTypeImageAndSave = createAsyncThunk(
  "images/VideTypeImageAndSave",
  async (type, { getState, dispatch }) => {
    dispatch(GestionImage.actions.VideTypeImage(type));
    const { gestionImage } = getState();
    console.log(gestionImage);
    const { allImages } = gestionImage;
    await saveImages({ allImages });
  }
);
export const addImageAndSave = createAsyncThunk(
  "images/addImageAndSave",
  async (data, { getState, dispatch }) => {
    dispatch(
      GestionImage.actions.addImages({
        imageType: data.type,
        images: data.images,
      })
    );
    const { gestionImage } = getState();
    console.log(gestionImage);

    const { allImages } = gestionImage;
    await saveImages({ allImages });
  }
);

export const DeleteImageParTypeAndsave = createAsyncThunk(
  "images/DeleteImageParTypeAndsave",
  async (data, { getState, dispatch }) => {
    console.log(data);
    dispatch(GestionImage.actions.DeleteImageParType(data));
    const { gestionImage } = getState();
    console.log(gestionImage);

    const { allImages } = gestionImage;
    await saveImages({ allImages });
  }
);

export const initializeState = createAsyncThunk(
  "images/initialize",
  async () => {
    const images = await getImages();
    return images.allImages
      ? images.allImages
      : {  Evenements: [] };
  }
);

export const GestionImage = createSlice({
  name: "gestion image",
  initialState: {
    allImages: { Evenements: [] },
    loading: "false", // 'idle', 'loading', 'succeeded', 'failed'
  },
  reducers: {
    addImages: (state, action) => {
      const { imageType, images } = action.payload;

      if (state.allImages.hasOwnProperty(imageType)) {
        state.allImages[imageType].push(...images);
      } else {
        state.allImages[imageType] = [...images];
      }
    },
    VidePanier: (state) => {
      state.allImages = { Evenements: [] };
      saveImages(state.allImages);
    },

    VidePolaroid: (state) => {
      state.allImages.polaroid = [];
      saveImages(state.allImages);
    },
    VideTypeImage: (state, action) => {
      state.allImages[action.payload] = [];
    },
    DeleteImageParType: (state, action) => {
      state.allImages[action.payload.type] = state.allImages[
        action.payload.type
      ].filter((item, i) => i !== action.payload.index);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializeState.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(initializeState.fulfilled, (state, action) => {
        state.loading = false;
        state.allImages = action.payload;
      })
      .addCase(initializeState.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { VidePanier, VideTypeImage } = GestionImage.actions;

export default GestionImage.reducer;
