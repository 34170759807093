import { Grid, Box, Typography, Badge, TextField } from "@mui/material";

import { BackLoadingSimple } from "../../composant/loading/backLoading";
import { useState } from "react";
import imageCompression from "browser-image-compression";
import { useDispatch, useSelector } from "react-redux";
import { addImageAndSave } from "../../store/slice/gestionImageSlice";
import { useNavigate } from "react-router";
import ImageCard from "../../composant/ImageGallrey";
import PhotoImportCard from "../../composant/UploadFiles";
import { ImportButton } from "../../composant/UploadFiles";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useParams } from "react-router";
import { InputAdornment } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import DrawerAppBar from "../../composant/navbar/Navbar";
export default function ImageUpload() {
  const [loading, setLoading] = useState(false);
  const [images, setImage] = useState([]);
  const { Produits } = useSelector((state) => state.dataApp);
  const { type } = useParams();
  const dataProduit = Produits.find((p) => p.NomProduit === type);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  async function AddImage(e) {
    setLoading(true);
    const acceptedFiles = e.target.files;

    const imageUploadPromises = Array.from(acceptedFiles).map((file) => {
      const options = {
        maxSizeMB: 0.5, // Maximum size in MB
        maxWidthOrHeight: 1920, // Resize the image if it is larger than 1920px
        useWebWorker: true,
      };
      return imageCompression(file, options); // Ensure the function returns the promise
    });

    try {
      const compressedFiles = await Promise.all(imageUploadPromises);
      setImage((prevImages) => [
        ...prevImages,
        ...compressedFiles.map((compressedFile, index) => ({
          name: acceptedFiles[index].name,
          file: compressedFile,
          idProduit: dataProduit.id,
        })),
      ]);
    } catch (error) {
      alert("Error uploading and compressing images:", error);
      setLoading(false);
      // Handle the error appropriately
    } finally {
      setLoading(false);
    }
  }

  const addpanier = () => {
    dispatch(addImageAndSave({ images: images, type: type }));
    navigate("/panier");
  };

  const deleteImage = async (index) => {
    setImage(() => images.filter((item, i) => i !== index));
  };

  const handleTextChange = (index, newText) => {
    console.log(newText);
    if (newText.length < 20) {
      setImage((currentImages) =>
        currentImages.map((img, imgIndex) =>
          imgIndex === index ? { ...img, text: newText } : img
        )
      );
    }
  };
  console.log(images);

  return (
    <>
      <DrawerAppBar />
      <div style={{ background: "#f7f7f7", minHeight: "100vh" }}>
        <Box>
          <Grid
            container
            spacing={4}
            sx={{
              marginTop: "8%",
              marginBottom: {
                xs: "15%", // Applies to xs and up, until overridden
                md: "5%", // Overrides the above value from md breakpoint and up
              },
            }}
          >
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h6"
                  component="h2"
                  sx={{ fontWeight: "regular" }}
                >
                  Tirage photo
                </Typography>
                <Box
                  component="span"
                  sx={{
                    // margin left and right for the divider
                    display: "inline-block",
                    height: "24px",
                    width: "1px",
                    // use your theme's text primary color
                  }}
                />
                <Typography
                  variant="h6"
                  component="h2"
                  sx={{ fontWeight: "bold" }}
                >
                  {" "}
                  - Tirage {type}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4} textAlign="center">
                {images.length === 0 && (
                  <Grid item xs={12} md={6}>
                    <Box
                      component="img"
                      src={dataProduit?.ImageUpload}
                      alt="ImageTirage"
                      width="80%"
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  md={images.length === 0 ? 6 : 12}
                  textAlign="center"
                >
                  <PhotoImportCard
                    AddImage={AddImage}
                    affichage={images.length === 0}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ width: "100%" }}>
              <Grid container spacing={4} textAlign="center">
                {images?.map((img, index) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={3}
                    key={index}
                    sx={{ width: "100%" }}
                    textAlign="center"
                  >
                    <ImageCard
                      src={URL.createObjectURL(img.file)}
                      onDelete={() => deleteImage(index)}
                    />
                    <TextField
                      key={index}
                      variant="outlined"
                      sx={{
                        width: "80%",
                        background: "white",
                        marginTop: "10px",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "grey",
                            borderRadius: "15px",
                          },
                          "&:hover fieldset": {
                            borderColor: "black", // Border color when input is not focused
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black", // Border color when input is focused
                          },
                        },
                      }}
                      value={img.text || ""}
                      onChange={(e) => handleTextChange(index, e.target.value)}
                      placeholder="Votre Texte"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CheckCircleOutline />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>

          {images.length !== 0 && (
            <>
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <footer
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    borderTop: "1px solid #ccc",
                    position: "fixed",
                    left: "0",
                    bottom: "0",
                    width: "100%",
                    background: "white",
                    paddingLeft: "2%",
                    paddingRight: "2%",
                  }}
                >
                  <ImportButton
                    variant="contained"
                    height="70px"
                    startIcon={
                      <Badge
                        badgeContent={images.length}
                        sx={{
                          "& .MuiBadge-badge": {
                            color: "#e63e57",
                            backgroundColor: "white",
                          },
                        }}
                      >
                        <AddShoppingCartIcon />
                      </Badge>
                    }
                    onClick={addpanier}
                  >
                    Ajouter au Panier
                  </ImportButton>
                </footer>
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <footer
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    position: "fixed",
                    left: "0",
                    bottom: "0",
                    width: "100%",
                    background: "white",
                    paddingLeft: "2%",
                    paddingRight: "2%",
                  }}
                >
                  <ImportButton
                    variant="contained"
                    fullWidth
                    height="70px"
                    startIcon={
                      <Badge
                        badgeContent={images.length}
                        fontSize="large"
                        sx={{
                          "& .MuiBadge-badge": {
                            color: "#e63e57",
                            backgroundColor: "white",
                          },
                        }}
                      >
                        <AddShoppingCartIcon />
                      </Badge>
                    }
                    onClick={addpanier}
                  >
                    imprimer
                  </ImportButton>
                </footer>
              </Box>
            </>
          )}

          <BackLoadingSimple open={loading} />
        </Box>
      </div>
    </>
  );
}
