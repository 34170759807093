import { Route, Routes, Navigate } from "react-router";
import { BrowserRouter } from "react-router-dom";
import Home from "./page/Client/home";
import InfoClient from "./page/Client/command";
import Panier from "./page/Client/Panier";
import ImageUpload from "./page/Client/UploadImage";
import { CssBaseline } from "@mui/material";
import Login from "./page/Admin/auth/login";
import { useSelector } from "react-redux";
import CommandDashbord from "./page/Admin/commadDasbord";
import ImprimnatDashbord from "./page/Admin/Imprimantdashbord";
import ParamsDahbord from "./page/Admin/Paramsdashbord";
import SerchCommand from "./page/Admin/sherchCommand";
import CommandTraiteParCode from "./page/Admin/commandTraiteParcode";

export const priviteRoute = [
  { path: "/admin", element: <CommandDashbord /> },
  { path: "/admin/command", element: <CommandDashbord /> },
  { path: "/admin/imprimant", element: <ImprimnatDashbord /> },
  { path: "/admin/parametre", element: <ParamsDahbord /> },
  { path: "/admin/sercheCommand", element: <SerchCommand /> },
  { path: "/admin/imageTraite/:id", element: <CommandTraiteParCode /> },
];

function IndexRoute() {
  const { IsAuth } = useSelector((state) => state.Auth);
  return (
    <div>
      <BrowserRouter>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/produit/:type" element={<ImageUpload />} />
          <Route path="/panier" element={<Panier />} />
          <Route path="/commande/:type" element={<InfoClient />} />
          <Route path="/login" element={<Login />} />
          {priviteRoute.map((p) => (
            <Route
              path={p.path}
              element={
                IsAuth ? (
                  p.element
                ) : (
                  <Navigate state={{ form: p.path }} to="/login" />
                )
              }
            />
          ))}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default IndexRoute;
