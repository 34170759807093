import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const GetInfoClient = createAsyncThunk("Get Info Client", async () => {
  const existingData = localStorage.getItem("infoclient");
  return existingData ? JSON.parse(existingData) : {};
});

export const InfoClient = createSlice({
  name: "InfoClient",
  initialState: {
    loading: false,
    clientCommand: {},
  },
  reducers: {
    StorageDataClient: (state, action) => {
      const existingData = localStorage.getItem("infoclient");

      if (existingData) {
        const existingParsedData = JSON.parse(existingData);
        let updatedData = { ...existingParsedData };
        Object.keys(action.payload).forEach((key) => {
          if (action.payload[key] !== null) {
            updatedData[key] = action.payload[key];
          } else {
            updatedData[key] = existingParsedData[key];
          }
        });
        state.clientCommand = updatedData;
        localStorage.setItem("infoclient", JSON.stringify(updatedData));
      } else {
        state.clientCommand = action.payload;
        localStorage.setItem("infoclient", JSON.stringify(action.payload));
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetInfoClient.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetInfoClient.fulfilled, (state, action) => {
        state.loading = false;
        state.clientCommand = action.payload;
      })
      .addCase(GetInfoClient.rejected, (state, action) => {
        state.loading = false;
      });
  },
});
export const { StorageDataClient } = InfoClient.actions;
export default InfoClient.reducer;